import React from 'react'
import Thanks from '../components/thanks'


const ThanksPage = () => {
    return (
        <>
            <Thanks />
        </>
    )
}

export default ThanksPage
